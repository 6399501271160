<template>
  <v-row no-gutters align="center">
    <v-col
      :order="2"
      :cols="6"
      :sm="6"
      :md="6"
      :lg="2"
      :xl="2"
      class="d-flex justify-sm-left justify-md-left justify-lg-left justify-xl-left justify-xs-left"
    >
      <!-- <ProductPromoFilter
        v-if="!hidePromoFilter"
        :promoFilter="promoFilter"
        @handlePromoFilter="handlePromoFilter"
      /> -->
    </v-col>

    <template v-if="facetsFilter">
      <v-col
        :order="$vuetify.breakpoint.mdAndDown ? 3 : 1"
        :cols="12"
        :sm="12"
        :md="12"
        :lg="8"
        :xl="8"
        class="d-flex justify-left"
      >
        <ProductFacetFilterMobile
          v-if="isMobile"
          :facets="facets"
          :facetsFilter="facetsFilter"
          :filteredProductsCount="filteredProductsCount"
          :hideFilterString="hideFilterString"
          @handleFacetFilterMobile="handleFacetFilterMobile"
        />

        <ProductFacetFilterDesktop
          v-else
          :facets="facets"
          :facetsFilter="facetsFilter"
          :hideFilterString="hideFilterString"
          @handleFacetFilter="handleFacetFilter"
        />
      </v-col>
    </template>

    <v-col
      :order="$vuetify.breakpoint.mdAndDown ? 1 : 3"
      :cols="6"
      :sm="6"
      :md="6"
      :lg="2"
      :xl="2"
      class="d-flex justify-sm-right justify-md-center justify-lg-center justify-xl-center justify-xs-left"
    >
      <ProductSortFilter
        v-if="!hideSortFilter"
        :sortFilter="sortFilter"
        @handleSortFilter="handleSortFilter"
      />
    </v-col>
  </v-row>
</template>

<script>
import ProductFacetFilterMobile from "@/components/product/filters/ProductFacetFilterMobile";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter";
import ProductFacetFilterDesktop from "@/components/product/filters/ProductFacetFilterDesktop";
// import ProductPromoFilter from "@/components/product/filters/ProductPromoFilter";
export default {
  name: "ProductFilters",
  components: {
    ProductFacetFilterMobile,
    ProductFacetFilterDesktop,
    // ProductPromoFilter,
    ProductSortFilter
  },
  props: {
    promoFilter: { type: Boolean },
    facets: { type: Array },
    facetsFilter: { type: Object },
    sortFilter: { type: Object },
    filteredProductsCount: { type: Number },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSortFilter: { type: Boolean, default: false }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    },
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    },
    handleFacetFilter(filters) {
      this.$emit("handleFacetFilter", filters);
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$emit("handleFacetFilterMobile", selectedFacetsXS);
    }
  }
};
</script>

<style></style>
