<template>
  <v-row no-gutters>
    <v-btn
      :disabled="!facets"
      class="d-flex align-center justify-space-between mobile-filters px-2"
      @click="dialog = true"
    >
      <span> {{ $tc("filter.filters", getFacetsLength) }}</span>
      <v-icon>mdi-filter-outline</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="facet-dialog"
    >
      <v-row class="d-flex justify-end filters-container-upper-row">
        <v-btn plain x-small @click="discard()" class="my-2 text-capitalize">
          <span>{{ $t("filter.button.discard") }}</span>
          <v-icon small class="ml-1">mdi-window-close</v-icon>
        </v-btn>
      </v-row>
      <div class="filters-container">
        <v-expansion-panels
          class="mobile-menu-filters"
          multiple
          flat
          v-model="panel"
        >
          <v-expansion-panel v-for="facet in facetsFiltered" :key="facet.name">
            <v-expansion-panel-header>
              {{ facet.name }}
              <div
                class="ml-2 text-caption"
                v-if="
                  facetsFilter[facet.parameterName] &&
                    facetsFilter[facet.parameterName].length > 0
                "
              >
                {{
                  $tc(
                    "filter.filters",
                    facetsFilter[facet.parameterName].length
                  )
                }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="panel-content">
              <v-row no-gutters class="d-flex flex-wrap">
                <v-checkbox
                  class="pr-2"
                  multiple
                  hide-details
                  v-for="value in facet.values"
                  :key="value.id"
                  :value="value.id"
                  :disabled="value.filteredCount == 0"
                  v-model="selectedFacetsXS[facet.parameterName]"
                  :label="value.name + ' (' + value.filteredCount + ')'"
                  @click="handleFacetFilterMobile()"
                ></v-checkbox>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-row
        no-gutters
        class="filters-container-buttons justify-space-between pa-1 align-top"
      >
        <v-btn
          color="primary"
          class="filter-button"
          v-on:click="applyFilters"
          depressed
          :loading="loadingApplyButton"
        >
          {{ $tc("filter.button.applyShowAllProducts", filteredProductsCount) }}
        </v-btn>
        <v-btn v-on:click="clearAllFilters" depressed class="filter-button">
          {{ $t("filter.button.reset") }}
        </v-btn>
      </v-row>
    </v-dialog>
  </v-row>
</template>
<style scoped lang="scss">
@import "@/scss/variables.scss";
.panel-content {
  background-color: $background-color-grey;
}
.filters-container {
  height: calc(100vh - 125px);
  overflow-y: auto;
  .v-expansion-panel-content__wrap {
    background-color: $background-color-grey;
  }
  .row {
    .col {
      padding: 10px;
    }
  }
  .v-expansion-panel {
    border-bottom: 1px solid $background-color-grey;
  }
}

.filters-container-upper-row {
  height: 40px;
}

.filters-container-buttons {
  border-top: 1px solid $background-color-grey;
  height: 85px;
  .filter-button {
    width: 46%;
    font-size: 0.75rem;
    @media (max-width: 360px) {
      font-size: 0.65rem;
    }
  }
}
</style>
<script>
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "ProductFacetFilterMobile",
  data() {
    return {
      dialog: false,
      panel: [],
      loadingApplyButton: false,
      initialFacets: {}
    };
  },
  props: {
    facets: { type: Array },
    facetsFilter: { type: Object },
    filteredProductsCount: { type: Number, required: false },
    hideFilterString: { type: String, default: "" }
  },
  computed: {
    getFacetsLength() {
      // const reducedFacets = Object.keys(this.selectedFacetsXS).reduce
      let facetsLength = 0;
      for (const prop in this.selectedFacetsXS) {
        facetsLength += this.selectedFacetsXS[prop].length;
      }
      return facetsLength;
    },
    selectedFacetsXS() {
      return cloneDeep(this.facetsFilter);
    },
    facetsFiltered() {
      let vm = this;
      return (
        vm.facets &&
        vm.facets.filter(item => vm.hideFilterString.indexOf(item.name) < 0)
      );
    }
  },
  methods: {
    handleFacetFilterMobile() {
      this.$emit("handleFacetFilterMobile", this.selectedFacetsXS);
      this.loadingApplyButton = true;
    },
    applyFilters() {
      // memorizing last filters applied for future use in case of discard()
      this.initialFacets = cloneDeep(this.selectedFacetsXS);
      this.dialog = false;
      this.panel = [];
    },
    clearAllFilters() {
      this.$emit("handleFacetFilterMobile", {});
      this.initialFacets = {};
      this.dialog = false;
      this.panel = [];
    },
    discard() {
      // eslint-disable-next-line no-debugger
      // debugger;
      this.$emit("handleFacetFilterMobile", this.initialFacets);
      this.dialog = false;
      this.panel = [];
    }
  },
  watch: {
    // retrieves filtered products quantity and sets text for Apply Button
    filteredProductsCount(val, oldVal) {
      if (val != oldVal) {
        this.loadingApplyButton = false;
      }
    }
  }
};
</script>

<style></style>
