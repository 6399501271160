<template>
  <div class="d-flex pt-lg-0 pt-md-3 pt-sm-3 pt-xs-3 product-facet-filter">
    <v-select
      v-for="facet in facetsFiltered"
      :key="facet.name"
      :items="facet.values"
      :item-text="facetLabel"
      :label="'Filtra per ' + facet.name"
      :item-disabled="facetDisabled"
      append-icon="mdi-chevron-down"
      class="pr-4 facet-filter d-flex"
      background-color="#fff"
      multiple
      chips
      small-chips
      clearable
      single-line
      solo
      dense
      item-value="id"
      hide-details
      deletable-chips
      @change="handleFacetFilter($event, facet.parameterName)"
      :value="facetsFilter[facet.parameterName]"
    >
      <template v-slot:selection="{ item, index }">
        <!-- <v-chip
          v-if="facetsFilter[facet.parameterName].length == 1 && index === 0"
        >
          <span>{{ item.name }}</span>
        </v-chip> -->
        <span
          class="grey--text caption"
          v-if="facetsFilter[facet.parameterName].length > 0 && index === 0"
        >
          (+{{ facetsFilter[facet.parameterName].length }} filtri)
        </span>
      </template>

      <!-- <template v-slot:item="data">
        {{ data.item.name }}
      </template> -->
    </v-select>
  </div>
</template>
<style global lang="scss">
.facet-filter {
  .v-select__selections {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    .v-chip {
      overflow: initial;
    }
  }
}
</style>
<script>
export default {
  name: "ProductFacetFilter",
  //   data() {
  //       return {
  //           selectedFacetsXS : []
  //       }
  //   },
  props: {
    facets: { type: Array },
    facetsFilter: { type: Object },
    hideFilterString: { type: String, default: "" }
  },
  computed: {
    facetFilter: item => {
      return item.filteredCount > 0;
    },
    facetsFiltered() {
      let vm = this;
      return (
        vm.facets &&
        vm.facets.filter(item => vm.hideFilterString.indexOf(item.name) < 0)
      );
    }
  },
  methods: {
    handleFacetFilter(facets, parameterName) {
      if (facets.length == 0) {
        setTimeout(function() {
          document.querySelector(".product-grid").click();
        }, 200);
      }
      this.$emit("handleFacetFilter", { facets, parameterName });
    },
    facetLabel(item) {
      return item.name + " (" + item.filteredCount + ")";
    },
    facetDisabled: facet => facet.filteredCount == 0
  }
};
</script>

<style></style>
