<template>
  <v-select
    label="ordina per"
    class="ps-4 pt-0"
    :items="sortOptions"
    :value="sortFilter"
    @change="handleSortFilter"
    hide-details
  />
</template>

<script>
export default {
  name: "ProductSortFilter",
  data() {
    return {
      sortOptions: [
        { value: "", text: "Rilevanza", icon: "ion-arrow-up-c" },
        { value: "pri_asc", text: "Prezzo crescente", icon: "ion-arrow-up-c" },
        {
          value: "pri_des",
          text: "Prezzo decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_asc",
          text: "Prezzo al kg crescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_des",
          text: "Prezzo al kg descrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "alf_asc",
          text: "Alfabetico crescente",
          icon: "ion-arrow-up-c"
        }
      ]
    };
  },
  props: ["sortFilter"],
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    }
  }
};
</script>

<style></style>
